<template>
  <ion-header>
    <ion-row>
      <ion-col size="9" class="ion-padding-top">
        <ion-row class="ion-justify-content-center">
          <ion-col size="auto">
            <h4 size="medium">Nuovo Ordine</h4>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="ion-align-items-center ion-margin-top">
        <ion-icon
          class="arrow-down"
          @click="closeModal"
          :icon="close"
        ></ion-icon>
      </ion-col>
    </ion-row>
  </ion-header>
  <ion-content>
    <ion-card class="no-background">
      <ion-list lines="none">
        <ion-item>
          <ion-label position="stacked">Nome Cliente</ion-label>
          <ion-input
            v-model="item.nomeCliente"
            inputmode="text"
            maxlength="50"
            required
            placeholder="Scrivi il nome del cliente..."
          ></ion-input>
        </ion-item>
        <div class="length-indicator">{{ item.nomeCliente.length }}/ 50</div>
        <ion-item class="ion-margin-top">
          <ion-label position="stacked">Seleziona opzione prodotto</ion-label>
          <ion-select
            interface="action-sheet"
            placeholder="Seleziona opzione prodotto..."
            v-model="opzioneSelezionata"
          >
            <ion-select-option
              v-for="option in options"
              :key="option"
              :value="option.value"
            >
              {{ option.text }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <br />
        <ion-item>
          <ion-label position="stacked"> Numero commessa </ion-label>
          <ion-input
            type="number"
            inputmode="number"
            v-model="item.numeroCommessa"
            placeholder="Inserisci il numero commessa..."
          >
          </ion-input>
        </ion-item>
        <br />
        <ion-item class="fatturato">
          <ion-label position="stacked"> Margine </ion-label>

          <ion-input
            inputmode="number"
            :value="computed_margine_value"
            disabled
          ></ion-input>
          <div>
            <p v-if="computed_margine_type == 'euro'" class="valuta">€</p>
            <p v-else-if="computed_margine_type == 'perc'" class="valuta">%</p>
          </div>
        </ion-item>

        <ion-item class="ion-margin-top">
          <!-- <ion-label position="stacked">Note</ion-label> -->
          <ion-textarea
            autoGrow
            autocapitalize
            v-model="firstMessage"
            placeholder="Scrivi il tuo messaggio..."
          >
          </ion-textarea>
        </ion-item>
      </ion-list>
    </ion-card>
    <div class="post-pic">
      <img v-if="previewImgUrl" :src="previewImgUrl" @error="handleImgError" />
      <div v-else class="input">
        <label>
          <ion-icon
            :icon="camera"
            @click="addFiles()"
            class="camera-icon"
          ></ion-icon>
        </label>
        <input type="file" ref="files" @change="handleFilesUpload($event)" />
      </div>
    </div>
  </ion-content>
  <ion-row class="ion-justify-content-center">
    <ion-col size="auto">
      <ion-button
        v-if="!loading"
        :disabled="notValidTicket"
        @click="sendTicket"
      >
        Invia</ion-button
      >
      <ion-button v-else disabled> Loading...</ion-button>
    </ion-col>
  </ion-row>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonCard,
  modalController,
} from "@ionic/vue";
import { close, camera } from "ionicons/icons";
import UserService from "../../common/mixins/User";

import ApiService from "../../common/service.api";
import { defineComponent, getCurrentScope } from "vue";

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonList,
    IonItem,
    IonTextarea,
    IonCard,
  },
  name: "ModalNewKpiChat",

  props: {
    options: Array,
  },
  data() {
    return {
      loading: false,
      description: "",
      item: {
        data: new Date(),
        nomeCliente: "",
        numeroCommessa: "",
        created_by: 0,
        created_by_string: "",
        product_id: 0,
        product_text: "",
        id_status: 1,
        status_text: "",
        need_reply: true,
        solved: true,
        kpiValue: 0,
        margineValue: 0,
      },

      firstMessage: "",
      userId: 0,
      files: [],

      opzioneSelezionata: 0,
      previewImgUrl: "",
      idOreder: 0,

      close,
      camera,
    };
  },
  mixins: [UserService],
  created() {
    this.resetData();
    this.getCurrentUser().then((res) => {
      this.item.created_by = res.id;
      this.userId = res.id;
    });
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    resetData() {
      this.item = {
        data: new Date(),
        nomeCliente: "",
        numeroCommessa: "",
        created_by: 0,
        created_by_string: "",
        product_id: 0,
        product_text: "",
        id_status: 1,
        status_text: "",
        need_reply: true,
        solved: true,
        kpiValue: 0,
        margineValue: 0,
      };
      this.firstMessage = "";
    },
    closeModal() {
      return modalController.dismiss();
    },
    async sendTicket() {
      this.loading = true;
      this.item.product_id = this.opzioneSelezionata;

      ApiService.post("SalesChat/InsertOrder", this.item)
        .then(async (res) => {
          if (res) {
            console.log("SALES CHAT / INSERT ORDER ->  RES: ", res);
            this.idOreder = res.data.id;
            await this.sendMessage();
            this.closeModal();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload(e) {
      const file = e.target.files[0];

      this.previewImgUrl = URL.createObjectURL(file);
      this.files.push(file);
    },
    async sendMessage() {
      const message = {
        id: null,
        data: new Date().toJSON(),
        message:
          this.firstMessage.length > 0
            ? this.firstMessage
            : this.item.numeroCommessa + " - " + this.item.nomeCliente,
        userType: "U",
        id_user: this.userId,
        files: [],
      };
      const formData = new FormData();
      formData.append("file", this.files);
      const promises = [];
      for (let i = 0; i < this.files.length; i++) {
        const formData = new FormData();
        formData.append("file", this.files[i]);
        promises.push(
          ApiService.post("Upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
        );
      }
      console.log("promises, ", promises);
      await Promise.all(promises).then((values) => {
        values.map((res) => {
          console.log("--- FILE UPLOADED ---", res);
          message.files.push({
            file_url: res.data,
          });
        });
        console.log("MESSAGE TO SEND ", message);
        ApiService.post(
          "SalesChat/NewMessage?idOrder=" + this.idOreder,
          message
        )
          .then((res) => {
            if (res) {
              console.log("EVENTO INVIATO NUOVA CONVERSAZIONE CREATA", res);
              this.$emit("nuovaConversazioneCreata");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  computed: {
    notValidTicket() {
      let item = this.item;
      if (
        //   item.id == 0 ||
        item.nomeCliente == "" ||
        item.numeroCommessa == 0 ||
        item.created_by == 0 ||
        this.opzioneSelezionata == 0
        //   item.kpiValue == 0
      ) {
        return true;
      }
      return false;
    },
    computed_margine_type() {
      let value = 0;
      if (this.opzioneSelezionata != 0) {
        this.options.forEach((element) => {
          if (element.value == this.opzioneSelezionata) {
            value = element.margine_type;
          }
        });
      } else {
        value = "euro";
      }
      return value;
    },
    computed_margine_value() {
      let value = 0;
      if (this.opzioneSelezionata != 0) {
        this.options.forEach((element) => {
          if (element.value == this.opzioneSelezionata) {
            value = element.margine;
          }
        });
      } else {
        value = "euro";
      }
      return value;
    },
  },
  watch: {
    opzioneSelezionata: {
      deep: true,
      handler(o, n) {
        this.options.forEach((element) => {
          if (element.value == this.opzioneSelezionata) {
            this.item.kpiValue = element.margine;
          }
        });
      },
    },
  },
});
</script>

<style scoped>
/* * {
  border: 1px solid greenyellow;
} */

.emmanuele {
  border: 1px solid red;
}

ion-item {
  border: 1px solid rgb(172, 170, 170);
  border-radius: 15px;
}

ion-icon {
  font-size: 2rem;
  margin-bottom: 8px;
  color: rgb(141, 141, 141);
}

.arrow-down {
  margin: 8px 0;
}

.length-indicator {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  z-index: 1;
}

.post-pic {
  display: grid;
  place-items: center;
  width: 100%;
}

.post-pic img {
  width: 100%;
  height: auto;
  filter: opacity(0.5);
  border-radius: 15px;
}

.camera-icon {
  /* margin-top: 3rem; */
  color: var(--ion-color-primary);
  font-size: 5rem;
}

input[type="file"] {
  position: absolute;
  top: -500px;
}

.fatturato {
  position: relative;
}

.valuta {
  position: absolute;
  bottom: 0;
  right: 1rem;
  font-size: 1rem;
}
</style>
